<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <template v-slot:search-bar>
      <ion-toolbar>
        <ion-searchbar
          placeholder="Search by name"
          v-model="search_string"
          @ionChange="search"
        ></ion-searchbar>
      </ion-toolbar>
    </template>
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-student :resources="resources"></list-student>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setCreateModalState(true)">
        <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab> -->

    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button>
        <ion-icon
          :ios="chevronBackCircleOutline"
          :md="chevronBackCircleOutline"
        ></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="start">
        <ion-fab-button @click="setCreateModalState(true)">
          <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button @click="setFilterModalState(true)">
          <ion-icon :ios="funnelOutline" :md="funnelOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>

    <ion-modal
      :is-open="createModalState"
      @didDismiss="setCreateModalState(false)"
    >
      <base-modal
        title="Create Student"
        @closeModal="setCreateModalState(false)"
      >
        <create-student
          @closeModal="setCreateModalState(false)"
        ></create-student>
      </base-modal>
    </ion-modal>

    <ion-modal
      :is-open="filterModalState"
      @didDismiss="setFilterModalState(false)"
    >
      <base-modal
        title="Filter Subject"
        @closeModal="setFilterModalState(false)"
      >
        <filter-student
          @closeModal="setFilterModalState(false)"
        ></filter-student>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import CreateStudent from "../../Components/User/Student/CreateStudent";
import ListStudent from "../../Components/User/Student/ListStudent";
import FilterStudent from "../../Components/User/Student/FilterStudent";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabList,
  IonIcon,
  IonFabButton,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  IonSearchbar,
} from "@ionic/vue";

import {
  addOutline,
  funnelOutline,
  chevronBackCircleOutline,
} from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    BaseModal,
    CreateStudent,
    ListStudent,
    FilterStudent,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonFabList,
    IonIcon,
    IonFabButton,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    IonSearchbar,
  },

  data() {
    return {
      title: "Students",
      backLink: "user",
      reloadable: true,

      search_string: null,

      createModalState: false,
      filterModalState: false,

      addOutline,
      funnelOutline,
      chevronBackCircleOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["student/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = false) {
      await this.$store.dispatch("student/resources", {
        refresh: refresh,
        search_string: this.search_string,
      });
    },
    loadData(event) {
      this.fetch();
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("student/resources", {
        refresh: true,
        search_string: this.search_string,
      });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },
    setFilterModalState(state) {
      this.filterModalState = state;
    },

    search() {
      this.refresh();
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<style scoped>
</style>
