<template>
  <ion-list v-if="resources != null">
    <ion-item
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
      class="hydrated link"
    >
      <ion-label @click="openPopover(item, $event)">
        <h2 :class="{ disabledItem: item.roles[0].name !== 'student' }">
          {{ item.user_detail.name }}
        </h2>
        <p v-if="item.student">
          {{ item.student.section_standard.standard.name }} -
          {{ item.student.section_standard.section.name }} [Roll:
          {{ item.student.roll_no }}]
        </p>
        <p v-else>No class assigned.</p>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No Students found</p>
    </ion-list-header>
  </ion-list>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal
      title="Update Student Profile"
      @closeModal="setUpdateModalState(false)"
    >
      <update-student
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-student>
    </base-modal>
  </ion-modal>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="'Session ' + selectedResource.name"
    >
      <ion-item button @click="setResourceToUpdate(selectedResource.id)"
        >Edit</ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import BaseModal from "../../../Base/BaseModal";
import UpdateStudent from "../Student/UpdateStudent";
import BasePopover from "../../../Base/BasePopover.vue";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonModal,
  IonPopover,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonModal,
    BaseModal,
    UpdateStudent,
    IonPopover,
    BasePopover,
  },
  data() {
    return {
      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      updateModalState: false,

      resourceToUpdate: {
        id: null,
        name: null,
      },
    };
  },
  methods: {
    slide(id) {
      this.$refs[`items-${id}`].$el.open();
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
      if (state == false) {
        this.setPopoverState(false);
      }
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },
  },
};
</script>
